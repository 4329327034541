import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <section>
      <h1>Nicht gefunden</h1>
      <p>
        Diese Seite konnten wir leider nicht finden :( Schau doch einfach auf
        die{' '}
        <Link to="/" title="Gehe zur Startseite">
          Startseite.
        </Link>
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
